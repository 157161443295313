import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginexistComponent } from './pre-login/loginexist/loginexist.component';
import { SharedModule } from './modules/shared.module';


const routes: Routes = [



  //-----------------------------------------------
  {
    path: '',
    //component: PreLoginComponent
    loadChildren: () => import('./modules/prelogin.module').then((m) => m.PreloginModule)
  },
  {
    path: 'returnurl',
    //component: PreLoginComponent
    loadChildren: () => import('./modules/prelogin.module').then((m) => m.PreloginModule)
  },
  {
    path: 'pre-login',
    loadChildren: () => import('./modules/prelogin.module').then((m) => m.PreloginModule)
  },
  {
    path: 'otp-verification',
    loadChildren: () => import('./modules/otp-verification-component.module').then((m) => m.OtpVerificationComponentModule)
  },
  {
    path: 'enter-pin',
    loadChildren: () => import('./modules/enter-pin-component.module').then((m) => m.EnterPinComponentModule)
  },
  {
    path: 'setup-pin',
    loadChildren: () => import('./modules/setup-pin-component.module').then((m) => m.SetupPinComponentModule)
  },
  {
    path: 'user-not-registered',
    loadChildren: () => import('./modules/login-for-not-register-user-component.module').then((m) => m.LoginForNotRegisterUserComponentModule)
  },
  {
    path: 'totp-verification',
    loadChildren: () => import('./modules/totp.module').then((m) => m.TotpModule)
  },
  {
    path: 'login-exist', component: LoginexistComponent
  },
  {
    path: 'vlogin-page',
    //component: PreLoginComponent
    loadChildren: () => import('./modules/prelogin.module').then((m) => m.PreloginModule)
  },
  {
    path: '**',
    loadChildren: () => import('./modules/prelogin.module').then((m) => m.PreloginModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [SharedModule, RouterModule]
})
export class AppRoutingModule {
  constructor() {
    console.log('AppRoutingModule loaded');
    let patterns = [
      '/SignOn/Auth',
      '/Home/ClientCookieAuth',
      '/login-customersupport',
      '/error',
      '/lwp',
      '/dealer'
    ];

    let domainUrl = document.URL;
    let hostOrigin = document.location.origin;
    let pathname = document.location.pathname;
    let queryParams = document.location.search;

    console.log('hostorigin', hostOrigin);
    //let domainUrl = document.URL;
    if (patterns.some(pattern => domainUrl.includes(pattern))) {
      let url = hostOrigin == 'https://weblogin-preprod.5paisa.com' || 'https://weblogin.5paisa.com' ? 'https://login.5paisa.com' + pathname + queryParams : 'https://weblogin.5paisa.com' + pathname + queryParams;
      //let url =  this.patternRedirectionURL + pathname + queryParams ;
      console.log('url', url);
      //this.utilService.logApiData('Domain', 'Domain - Redirection', JSON.stringify(domainUrl), JSON.stringify(url));
      window.location.href = url;
    }
  }
}