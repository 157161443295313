import {Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConstantService } from 'src/app/service/constant.service';
import { DataService } from 'src/app/service/data.service';
import { EncryptionService } from 'src/app/service/encryption.service';
import { AfterViewInit, } from '@angular/core';
import { UtilService } from 'src/app/service/util.service';
import { MatDialog } from '@angular/material/dialog';
import { StoreDataServiceService } from 'src/app/store-data-service.service';
import { LoginApiShareService } from 'src/app/service/login-api-share.service';
import { APP_SETTINGS, AppSettings } from 'src/app/settings';

declare const window: any;
//declare function setCookie(cookieName,cookieValue)
@Component({
  selector: 'app-loginexist',
  templateUrl: './loginexist.component.html',
  styleUrls: ['./loginexist.component.css']
})
export class LoginexistComponent implements AfterViewInit {
  
  clientCodeFromStorage: any;
  userNameFromStorage: string;
  mobileNumberFromStorage: any;
  incorrectPIN = false;
  pinValue = '';
  pinError='';
  isTwoFA: boolean = true;
  returnUrl :string;
  showLoader :boolean =false;
  isNomineepopupRequired :string='';
  isFNOpopupRequired : string='';

  otpArr: string[] = ['', '', '', '', '', ''];
  isCaptchaEnabled: string;
  isdisabled = true;
  
  userDataObj: any = {
    isDeviceBinded: '',
    deviceId: '',
    clientCode: '',
    clientStatus: '',
    displayMobileNumber: '',
    authType: '',
    loginState: '',
    userFirstName: '',
    isTwoFA: this.isTwoFA
};

  @ViewChild('pinOne') pinOne: ElementRef;
  @ViewChild('pinTwo') pinTwo: ElementRef;
  @ViewChild('pinThree') pinThree: ElementRef;
  @ViewChild('pinFour') pinFour: ElementRef;
  @ViewChild('pinFive') pinFive: ElementRef;
  @ViewChild('pinSix') pinSix: ElementRef;
  @ViewChild('btnVerified') btnVerified: ElementRef;

  loginExist = this.fb.group({
    clientCode: [null],
    userName: [null], 
    pinDigitOne: [null, Validators.required],
    pinDigitTwo: [null, Validators.required],
    pinDigitThree: [null, Validators.required],
    pinDigitFour: [null, Validators.required],
    pinDigitFive: [null, Validators.required],
    pinDigitSix: [null, Validators.required]   
  });


  constructor(
    @Inject(APP_SETTINGS) 
    private settings: AppSettings,
    private router: Router,
    private fb: FormBuilder,
    private encryptionService: EncryptionService,
    private constantService: ConstantService,
    private dataService :DataService,
    private utilService : UtilService,
    public dialog: MatDialog,
    private storeDataServiceService : StoreDataServiceService,
    private loginApiShareService : LoginApiShareService
  ) {
    this.isCaptchaEnabled = storeDataServiceService.getCaptchaEnabled();
  }

  ngAfterViewInit() {this.pinOne.nativeElement.focus()}

  renderTheCaptcha = () => {
    const callbackFunc = (token)=> {
      console.log('Turnstile token:', token);
      this.verifyToken(token);
    }
    window.turnstile.render('#example-container', {
        sitekey: '2x00000000000000000000AB',
        // 0x4AAAAAAAgDSwgYTe0R96Ed for always failure
        // 2x00000000000000000000AB for always failure
        //1x00000000000000000000AA  for always success
        //0x4AAAAAAAgDSwgYTe0R96Ed live key
        callback: callbackFunc,
        "error-callback":async function(error) {
            console.log('Turnstile error:', error);
            //UtilService.prototype.logApiData('0x4AAAAAAAgDSwgYTe0R96Ed', 'Failure - turnstile', JSON.stringify(error), JSON.stringify(error));
        }
        
    });
  }
  ngOnInit(): void {
    if(this.isCaptchaEnabled == 'Y'){
      if(window.turnstile){
       this.renderTheCaptcha();
      }else{
       window.onloadTurnstileCallback =  ()=> {
         this.renderTheCaptcha();
       }; 
     }

     }
     else{
       this.isdisabled = false;
     }
    localStorage.removeItem('showOtpWithDob');
    localStorage.removeItem('isEditClicked');
    this.isNomineepopupRequired = this.constantService.getDecryptedLocalStorageData('NomineePop');
    this.clientCodeFromStorage = this.constantService.getDecryptedLocalStorageData('clientCode');
    this.userNameFromStorage = this.constantService.getDecryptedLocalStorageData('ClientName');
    this.mobileNumberFromStorage = this.constantService.getDecryptedLocalStorageData('mobileNumber');
    this.returnUrl = this.constantService.getDecryptedLocalStorageData('returnUrl');
    
    this.dataService.data.subscribe((result) => {
      if(result!=''){
        this.userDataObj.deviceId =result.data.deviceId;
        this.userDataObj.noOfDeviceBinded = result.data.NoOfDeviceBinded;
      }
      else{
        this.router.navigateByUrl('pre-login');
      }
    });
  }
  onKeyUpHandleFocus(event, index) : void{
    try{
      this.incorrectPIN = false;
      var targetElement = event.target as HTMLInputElement;
    if (!/^[0-9]+$/.test(event.key) && event.key != 'Backspace' && event.key != 'Tab') {
        event.preventDefault();
    } else if (String(this.otpArr[index]).length >= 0 && event.key != 'Backspace' && event.key != 'Tab') {
        event.preventDefault();
        var nextElement = targetElement.nextSibling as HTMLInputElement;
        this.otpArr[index] = event.key;
        if (index < 5) {
            nextElement.focus();
        }
    } else if (event.key == 'Backspace') {
        this.otpArr[index] = '';
        var prevElement = targetElement.previousSibling as HTMLInputElement;
        event.preventDefault();
        if(index > 0) {
            prevElement.focus();
        }
    }

    }
    catch(error){
      console.log(error)
      
    }
    
  }
  async verifyPin() :Promise<void> {
    try{
      this.showLoader =true;
      this.pinOne = this.loginExist.controls['pinDigitOne'].value;
      this.pinTwo = this.loginExist.controls['pinDigitTwo'].value;
      this.pinThree = this.loginExist.controls['pinDigitThree'].value;
      this.pinFour = this.loginExist.controls['pinDigitFour'].value;
      this.pinFive = this.loginExist.controls['pinDigitFive'].value;
      this.pinSix = this.loginExist.controls['pinDigitSix'].value;
  
      this.pinValue =
        this.pinOne.toString() +
        this.pinTwo.toString() +
        this.pinThree.toString() +
        this.pinFour.toString() +
        this.pinFive.toString() +
        this.pinSix.toString();
  
        var encryptPinVal =this.encryptionService.encryptUsingAES_256(this.pinValue);
        const request = this.verifyMpinRequest(encryptPinVal);
        await this.loginApiShareService.userpinVerificationwithDevice(request)
        .then(async(pinVerificationResponse) => {
          if (pinVerificationResponse?.error) {
            this.utilService.logApiData(this.clientCodeFromStorage, 'Failure - userpinverificationwithdevice', JSON.stringify(request), JSON.stringify(pinVerificationResponse));
            this.showLoader = false; 
            return
          }
          else {
            this.utilService.logApiData(this.clientCodeFromStorage,'userpinverificationwithdevice',JSON.stringify(request),JSON.stringify(pinVerificationResponse));
          }
          
          if (pinVerificationResponse.body.Status == 0 &&pinVerificationResponse.head.status == 0) {
          //if(environment.ShowFnoPopUp = true){ //Check this Param from firebase
            let isEnabledPop = this.storeDataServiceService.getFirebasePopupConfigration();
            if(isEnabledPop == true){
                await this.utilService.getClientPersonalDetails(pinVerificationResponse.head.JwtToken,this.clientCodeFromStorage).then((responsePersonalData) => {
                  if(responsePersonalData != null){
                    if(responsePersonalData.body.clientInfoResult.ClientInfo.length > 0){
                      if(responsePersonalData.body.clientInfoResult.ClientInfo[0]?.br_mobile){
                        this.constantService.setEncryptedLocalStorageData('mobileNumber',responsePersonalData.body.clientInfoResult.ClientInfo[0].br_mobile);
                      }
                      if(responsePersonalData.body.clientInfoResult.ClientInfo[0].br_email !=null || responsePersonalData.body.clientInfoResult.ClientInfo[0].br_email != undefined || responsePersonalData.body.clientInfoResult.ClientInfo[0].br_email != ''){
                        this.constantService.setEncryptedLocalStorageData('Email',responsePersonalData.body.clientInfoResult.ClientInfo[0].br_email);
                      }
                      if(responsePersonalData.body.clientInfoResult.ClientInfo[0].cm_name !=null || responsePersonalData.body.clientInfoResult.ClientInfo[0].cm_name != undefined || responsePersonalData.body.clientInfoResult.ClientInfo[0].cm_name != ''){
                        this.constantService.setEncryptedLocalStorageData('ClientName',responsePersonalData.body.clientInfoResult.ClientInfo[0].cm_name.split(' ')[0]);
                      }
                      if(responsePersonalData.body.clientInfoResult.ClientInfo[0].cm_IsNSEFO =='Y')
                      {
                        this.isFNOpopupRequired ='YES';
                        this.constantService.setLocalStorageData("AllowNseDerivative","Y");
                      }
                      if(responsePersonalData.body.clientInfoResult.ClientInfo[0].cm_IsBSEFO =='Y')
                       {
                        this.isFNOpopupRequired ='YES';
                        this.constantService.setLocalStorageData("AllowBseDerivative","Y");
                      }
                    }
                  }
                  else{
                    this.isFNOpopupRequired ='NO';
                    this.constantService.setLocalStorageData("AllowNseDerivative","N");
                    this.constantService.setLocalStorageData("AllowBseDerivative","N");
                  }
                });
            }
            else{
              this.isFNOpopupRequired ='NO';
              this.constantService.setLocalStorageData("AllowNseDerivative","N");
              this.constantService.setLocalStorageData("AllowBseDerivative","N");
            }
            this.incorrectPIN = false;
            this.storeDataServiceService.setJwtToken(pinVerificationResponse.head.JwtToken);
            this.storeDataServiceService.set5paisaCookie(pinVerificationResponse.head._5paisacookie);
            this.storeDataServiceService.setSolaceCred(pinVerificationResponse.head.SolaceCred);

            //setCookie('JwtToken',pinVerificationResponse.data.head.JwtToken);
            //setCookie('5paisacookie',pinVerificationResponse.data.head._5paisacookie); // set Cookie
            this.constantService.setEncryptedLocalStorageData('5Pcookies',pinVerificationResponse.head._5paisacookie); // set in local storage
            this.constantService.setEncryptedLocalStorageData('SolaceCred',pinVerificationResponse.head.SolaceCred); // set in local storage
            this.constantService.setEncryptedLocalStorageData('JwtToken',pinVerificationResponse.head.JwtToken);
            //setCookie('SolaceCred',pinVerificationResponse.data.head.SolaceCred); // set Cookie
             // skip API Calling if firebase 
            if (isEnabledPop == true) {
              await this.utilService.getIncomeRangeData(this.clientCodeFromStorage, pinVerificationResponse.head.JwtToken).then((response) => {
                if (response ==null) {
                  this.constantService.setLocalStorageData('incomeproof', false);
                  this.constantService.setLocalStorageData('emailproof', false);
                  this.constantService.setLocalStorageData('mobileproof', false);
                }
                else {
                  this.constantService.setLocalStorageData('incomeproof', response.body.isIncomeRangeConsentRequired);
                  this.constantService.setLocalStorageData('emailproof', response.body.isEmailConsentRequired);
                  this.constantService.setLocalStorageData('mobileproof', response.body.isMobileConsentRequired);
                }
              });
            }
            else {
              // if income data is null
              this.constantService.setLocalStorageData('incomeproof', false);
              this.constantService.setLocalStorageData('emailproof', false);
              this.constantService.setLocalStorageData('mobileproof', false);
            }
            this.showLoader =true; 
            await this.utilService.checkForDormantAccount().then((response) => {
              if(response.head != null || response.head != undefined){
                if(response.head.status == 1 && response.head.message == 'Success'){
                   this.storeDataServiceService.isDormantAccount = response.body.clientStatus;
                }
                else{
                  this.storeDataServiceService.isDormantAccount = 'A';
                }
              }
              else{
                this.storeDataServiceService.isDormantAccount = 'A';
              } 
            });
            if (this.storeDataServiceService.getNeedtoCallTradestationBetaApi() == 'Y') {
              let selectOptionUrl = this.constantService.getDecryptedLocalStorageData('selectedOption');
              if (selectOptionUrl == 'Tradestation' &&
                this.storeDataServiceService.userDetails[0]['redirectionType'] == null ||
                this.storeDataServiceService.userDetails[0]['redirectionType'] != '1') {
                await this.utilService.checkforTradestationRedirection(this.clientCodeFromStorage, this.storeDataServiceService.userDetails[0]['redirectionType'])
              }
            }
            else {
              if (this.storeDataServiceService.getTradestationRedirectionValue() == 'OT') {
                this.storeDataServiceService.userDetails[0]['redirectionType'] = '0'; // old tradestation
              }
              else {
                this.storeDataServiceService.userDetails[0]['redirectionType'] = '2'; // new tradestation
              }
            }
            this.utilService.checkForAllPopup();
          } 
          else if (
            pinVerificationResponse.body.Status == 1 &&
            pinVerificationResponse.head.status == 0
          ) {
               this.showLoader =false;  
               this.incorrectPIN = true;
               this.pinError = pinVerificationResponse.body.Message;
          }
          else if (
            pinVerificationResponse.body.Status == 0 &&
            pinVerificationResponse.head.status == "2"
          ) {
               this.showLoader =false;  
               this.incorrectPIN = true;
               this.pinError = pinVerificationResponse.head.statusDescription;
          }
        });
    }
    catch(e){
      this.showLoader =false;
      console.log(e,"verifyPin")
    }
  }

  verifyMpinRequest(mpinval) {
    const request = {
      head: {
        appName: "5PTRADE",
        appVer: "3.0",
        key: this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).ocpApimSubKey),
        osName: "Web",
        requestCode: "5PUPVV2"
    },
      body: {
          ClientCode: this.clientCodeFromStorage.toString().trim(),
          DeviceID: this.userDataObj.deviceId,
          Pin: mpinval,
          DeviceWithSMS: 'Y',
          PublicIP: this.storeDataServiceService.getIpAddress() == undefined ? this.constantService.LocalIP :this.storeDataServiceService.getIpAddress(),
          MobileNo: this.mobileNumberFromStorage,
          RequestType: 'AuthForLogin'
      }
  
      
    }
    return request;
  }

  async forgotMPIN() {
    this.showLoader =true;
    this.userDataObj.loginState = 'ForgotMPIN';
    this.userDataObj.isDeviceBinded =localStorage.getItem('isDeviceBinded');
    this.userDataObj.isTwoFA = localStorage.getItem('isTwoFA');
    this.constantService.setEncryptedLocalStorageData('showOtpWithDob', 'Y');
     let otpresponse = await this.utilService.getOTPForLogin(this.userDataObj.isTotpEnabled);
     if(otpresponse != null){
          if(otpresponse.Status == '0'){
            otpresponse['showOtpWithDob'] = 'Y'; // to show otp with dob
            this.dataService.sendData({
              data : otpresponse
            });
            this.router.navigateByUrl('otp-verification');
          }
          else if(otpresponse.Status == '3'){
            this.incorrectPIN = true;
            this.pinError = otpresponse.AlertMessage == '' ? otpresponse.Message : otpresponse.AlertMessage;
            return;
          }
          
     }
  }
  changeLogin():void{
    this.constantService.setEncryptedLocalStorageData('changeuser',true);
    this.router.navigate(["pre-login"]);
  }
  verifyToken=(token) =>{
    try {
      let request = {
        "token": token
      }
        this.loginApiShareService.verifyCaptchaToken(request).then((result) => {
          if (result.success) {
            this.utilService.logApiData('verifyToken', 'success - verifyToken', JSON.stringify(request), JSON.stringify(result));
            window.turnstile.remove();
            this.isdisabled = false;
          }
          else {
            this.utilService.logApiData('verifyToken', 'Failure - verifyToken', JSON.stringify(request), JSON.stringify(result));
            this.renderTheCaptcha() // regenerate captcha
            this.isdisabled = true;
          }
        }).catch((error) => {
          //this.utilService.logApiData('verifyToken', 'Failure - verifyToken - Function Error', JSON.stringify(request), JSON.stringify(error));
          console.log('error',error);
          
        });;
     
    }
    catch (error) {
      //this.utilService.logApiData('verifyToken', 'Failure - verifyToken - Function Error', JSON.stringify(token), JSON.stringify(error));
      console.error(error, "verifyToken");
    }
  }

}
