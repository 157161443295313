<div class="risk-disc">
  <h2>Risk disclosures on derivatives</h2>
  <hr />
  <div class="inner-wrapper">
    <p>Risk disclosure in the Equity Futures & Options segment aims to inform
        individual traders about the inherent risks involved in trading these
        derivative instruments. By disclosing these risks, regulatory bodies
        such as SEBI seek to ensure that traders are fully aware of the nature
        of these instruments and the challenges they pose.
    </p>
    <ul>
      <li>
        9 out of 10 individual traders in equity Futures and Options Segment,
        incurred net losses.
      </li>
      <li>
        On an average, loss makers registered net trading loss close to 50,000.
      </li>
      <li>
        Over and above the net trading losses incurred, loss makers expended an
        additional 28% of net trading losses as transaction costs.
      </li>
      <li>
        Those making net trading profits, incurred between 15% to 50% of such
        profits as transaction cost.
      </li>
    </ul>
    <p>
      Source: <span>SEBI Circular</span>
    </p>
    <mat-checkbox
      [checked]="defaultchecked"
      (change)="acknowledgement($event)"
      class="example-margin"
      >I acknowledge and confirm that i have gone through the above "Risk
      Disclosure"</mat-checkbox
    >

    <div class="bottom-button-wrapper">
      <button
        type="button"
        [disabled]="btndisabled"
        (click)="confirmation()"
        (keydown.enter)="confirmation()"
        class="btn-dark btn-block top_space"
      >
      Acknowledge
      </button>
    </div>
  </div>
</div>
