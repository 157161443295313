import { Inject, Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { APP_SETTINGS, AppSettings } from '../settings';

@Injectable({
    providedIn: 'root'
})
export class EncryptionService {
    // private readonly SALT = CryptoJS.enc.Base64.parse('SXZhbiBNZWR2ZWRldg==');
    // private readonly ITERATIONS = 1000;

    constructor(@Inject(APP_SETTINGS) 
    private settings: AppSettings) { }


  encryptUsingAES_256(input) {
        try {
            let _key = CryptoJS.enc.Hex.parse('842a357c4b385726090aa184b75b691075da95e6ddd4eb40');
            let _iv = CryptoJS.enc.Hex.parse('53471a3a3623160b53471a3a3623160b');
            let encrypted = CryptoJS.AES.encrypt(
              CryptoJS.enc.Utf8.parse(input), _key, {
                keySize: 128 / 8,
                iv: _iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
              });
            const encryptedData = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
            return encryptedData;
        } catch (e) {
          console.log("encryptUsingAES_256 :-",e); // Handle or log the error appropriately
          return null;
        }
        
  }
  decryptUsingAES_256(input) {
        try {
            if(input){
              let _key = CryptoJS.enc.Hex.parse('842a357c4b385726090aa184b75b691075da95e6ddd4eb40');
              let _iv = CryptoJS.enc.Hex.parse('53471a3a3623160b53471a3a3623160b');
          
              const decrypted = CryptoJS.AES.decrypt(input, _key, {
                iv: _iv
            });
      
              return decrypted.toString(CryptoJS.enc.Utf8);
            }
            else{
              return null;
            }
           
        } catch (e) {
          console.log("decryptUsingAES_256:- ",e); // Handle or log the error appropriately
          return null;
        }
        
  }
  decryptingClientMobileString(mobileNumber: string): string { // this method use for when user is coming with encrypted mobile number
        let decryptValue = '';
        try {
          if (mobileNumber) {
            const token = '576E5A7234753778217A25432A462D4A614E645267556B58703273357638792F'; // do not encrypt this token passing static for this
            const key = token.substring(0, 32);
            const iv = token.substring(0, 16);
            const encrypted = CryptoJS.enc.Base64.parse(mobileNumber);
            const keyBytes = CryptoJS.enc.Utf8.parse(key);
            const ivBytes = CryptoJS.enc.Utf8.parse(iv);
            const decrypted = CryptoJS.AES.decrypt(mobileNumber, keyBytes, {
              iv: ivBytes
          });
    
            decryptValue = decrypted.toString(CryptoJS.enc.Utf8);
          }
        } catch (e) {
          console.log("DecryptingClientMobileString:-",e); // Handle or log the error appropriately
        }
        return decryptValue;
  }
     



}
