// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  firebase: {
    apiKey: "AIzaSyB_gteKiDdJKB2dGZLD0X1GXEvZWa9XZXA",
    authDomain: "paisa-mf.firebaseapp.com",
    databaseURL: "https://paisa-mf.firebaseio.com",
    projectId: "paisa-mf",
    storageBucket: "paisa-mf.appspot.com",
    messagingSenderId: "945111842441",
    appId: "1:945111842441:web:62b18ca398857c472b5058",
    measurementId: "G-FW30J08J2Z",
  }

  // authBaseAPI : 'https://gateway.5paisa.com/AuthApi/',    //LIVE Auth API
  // pwaSpotbaseAPI : ' https://gateway.5paisa.com/pwaspot/',    //LIVE PWASPOT API
  // BoApi : 'https://gateway.5paisa.com/BoAPI/Common/', // live BoApi
  // ocpApimSubscriptionKey_BoApi : 'a8ed3b7ead504fa8903d9c7e0d23bc61', // Live Key
  // ocpApimSubscriptionKey: 'a8ed3b7ead504fa8903d9c7e0d23bc61',  //LIVE API subscription key
  // ocpApimSubKey: '8eeee59bdab88bace6189d001f96487e',
  // apiHeadKey: '4dddd59bdab77bace6189d001f96487e',
  // userId: 'ZyT47UW2g56',
  // password: 'H98qlU4Sn2',
  // Authorization : 'Basic NXBhaXNhVWF0OjVwYWlzYUB3ZWJVYXQ=',
  // pwaAllowUsers : '0',
  // keyValue : '842a357c4b385726090aa184b75b691075da95e6ddd4eb40', // For Crypto
  // ivValue : '53471a3a3623160b53471a3a3623160b', // For Crypto
  // AccountPending : 'https://eaccount.5paisa.com/ClientLogin/VerifyClientLoginMobile',
  // PWAUrl : 'https://pwaweb.5paisa.com/#/activation',
  // cookieExpireTime : 30,
  // tradestationUrl : 'https://tradestation.5paisa.com/ssologin',
  // noOfDeviceBinded :4,
  // logAPIUrl:'https://uatapi.5paisa.in/brokingcommonapi/api/login/v1/',
  // PrivateChecksumkey:'MDwwDQYJKoZIhvcNAQEBBQADKwAwKAIhAJ5Cj2yA/StpN7Bf1XayJ84Ta1aYR2V/0GmMI10wDhtlAgMBAAE=',
  // BoApi_param_key :'kahdAjhfdsd423jkoi3frDFD3vbxvzyXZ4S4dF',
  // ShowFnoPopUp: true,
  // ActivationMoficationUrl :'https://diygateway.5paisa.com/5peaccount/',
  // ActivationUrl :'https://gateway.5paisa.com/5peaccount/',
  // tokenUserName:'3pH1UiSyjsnh8hVq9PuRdFvzIhMz+3dsBagjmJpP8XkkoX5+f/5unYYmamsjZAmi',
  // tokenPassword:'4AYM8OOag1zEHsDbz5bLAjUekzVYwpywlLotOEBffag=',
  // Activation_Ocpkey:'a8ed3b7ead504fa8903d9c7e0d23bc61',
  // ActivationMerchentkey:'3s@nY!mNgOH9p0Td',
  // Ocpkey_nominee:'61d16cfb2ce24ae69e66c4911ec4fc9e',
  // Optout_Url:'https://emodify.5paisa.com/customer-dashboard/client-profile?source=nominee_declaration&appsource=App&nomtype=opt_in',
  // Activation_Ocp_key :'87b65866c33c41d6a4fc08e4c274b783',
  // Authorizationkey :'K1l2Z1luRWo0S3MvQXhWVENHb0VweDdpeTNvNlJldHc5WnV3b0xseWZZZz06K1l2Z1luRWo0S3MvQXhWVENHb0VweUlpQUdnWjRkSGt3a3VHL3ZqRHlpWVB5Y0xRdHc4V211cnNrVUhRSkxULw==',
  // authApiChecksumPrivateKey :'MDwwDQYJKoZIhvcNAQEBBQADKwAwKAIhAINkrQAw+cxsWexQ2g8Wn1oHQvPsA8dve8bLNbHuXTanAgMBAAE=',
  // termsconditionurl : 'https://www.5paisa.com/terms-and-conditions',
  // FnOUrl : 'https://fno.5paisa.com/',
  // tradeApiUrl : 'https://gateway.5paisa.com/tradeapi/',
  // encrptionTokenForMobileNumber :'576E5A7234753778217A25432A462D4A614E645267556B58703273357638792F',
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
