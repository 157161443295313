import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings, APP_SETTINGS } from 'src/app/settings';
import { EncryptionService } from 'src/app/service/encryption.service';
import { TimeoutError, catchError, delay,  timeout } from 'rxjs';
import { StoreDataServiceService } from '../store-data-service.service';

@Injectable({
    providedIn: 'root'
})

export class LoginApiShareService {
    constructor(
      private http: HttpClient,
      @Inject(APP_SETTINGS) private settings: AppSettings,
      private encryptionService: EncryptionService,
      private storeDataServiceService : StoreDataServiceService) {  
    }

    pwaSpotbaseAPI: any = this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).pwaSpotbaseAPI);
    private pwaSpotbaseAPIheaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).ocpApimSubscriptionKey)
    });

    authBaseAPI: any = this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).authBaseAPI);
    private authBaseAPIheaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).ocpApimSubscriptionKey),
        'UserID': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).userId),
        'Password': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).password)
    });

    boApi: any = this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).boApi);
    private boApiheaders = new HttpHeaders({
        'Authorization': 'Basic NXBhaXNhOjVwYWlzYUB3ZWI=',
        'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).ocpApimSubscriptionKeyBoApi)
    });

    activationUrl: any = this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).activationUrl);
    kycApiUrl: any = this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).kycApiUrl);
    private activationUrlHeaders = new HttpHeaders({
        'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).activationOcpkey),
        'ReqSource': 'Web',
    });

    activationModificationUrl: any = this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).activationModificationUrl);
    
    tradeApiUrl: any = this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).tradeApiUrl);

    async checkClientStatus(reqBody: any): Promise<any> {
        try {
        //   this.pwaSpotbaseAPIheaders = this.pwaSpotbaseAPIheaders.set('Authorization', authorization);
          let options = { headers: this.pwaSpotbaseAPIheaders };
          const data = await this.http.post(this.pwaSpotbaseAPI + 'V3/CheckClientStatus', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await checkClientStatus : ' + error);
          return error;
        }
    }
    async checkClientStatuswithToken(reqBody: any): Promise<any> {
      try {
      //   this.pwaSpotbaseAPIheaders = this.pwaSpotbaseAPIheaders.set('Authorization', authorization);
        let options = { headers: this.pwaSpotbaseAPIheaders };
        const data = await this.http.post(this.pwaSpotbaseAPI + 'token/V1/CheckClientStatus', reqBody, options).toPromise();
        return data;
      }
      catch (error) {
        console.log('Error fetching data with Async/Await checkClientStatus : ' + error);
        return error;
      }
  }

    async clientLoginWOPassword(clientCodeFromVerifyOTP:string, IsOnlyMF:string, AppSource:string, CompanyID:string, type:string): Promise<any> {
        try {
        //   this.pwaSpotbaseAPIheaders = this.pwaSpotbaseAPIheaders.set('Authorization', authorization);
        let queryParams = new HttpParams();
          queryParams = queryParams.append('clientCodeFromVerifyOTP', clientCodeFromVerifyOTP); 
          queryParams = queryParams.append('IsOnlyMF', IsOnlyMF); 
          queryParams = queryParams.append('AppSource', AppSource); 
          queryParams = queryParams.append('CompanyID', CompanyID); 
          queryParams = queryParams.append('type', type); 

          let options = { headers: this.pwaSpotbaseAPIheaders };
          const data = await this.http.get(this.pwaSpotbaseAPI + 'ClientLoginWOPassword'+ {params:queryParams}, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await clientLoginWOPassword : ' + error);
          return error;
        }
    }

    async loginviaOtpWithoutCookie(reqBody: any): Promise<any> {
        try {
        //   this.authBaseAPIheaders = this.authBaseAPIheaders.set('Authorization', authorization);
          let options = { headers: this.authBaseAPIheaders };
          const data = await this.http.post(this.authBaseAPI + 'V1/LoginViaOTPWithoutCookie', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await loginviaOtpWithoutCookie : ' + error);
          return error;
        }
    }

    async userpinVerificationWithClientCode(reqBody: any): Promise<any> {
        try {
        //   this.authBaseAPIheaders = this.authBaseAPIheaders.set('Authorization', authorization);
          let options = { headers: this.authBaseAPIheaders };
          const data = await this.http.post(this.authBaseAPI + 'UserPinVerificationWithClientCode', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await userpinverificationwithclientcode : ' + error);
          return error;
        }
    }

    async totpVerification(reqBody: any): Promise<any> {
        try {
          this.authBaseAPIheaders = this.authBaseAPIheaders.set('Ocp-Apim-Subscription-Key', this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).ocpApimSubscriptionKey));
          let options = { headers: this.authBaseAPIheaders };
          const data = await this.http.post(this.authBaseAPI + 'ValidateQRCodeForLogin', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await totpVerification : ' + error);
          return error;
        }
    }

    async setDeviceBinding(Cookie:string, reqBody: any): Promise<any> {
        try {
          this.authBaseAPIheaders = this.authBaseAPIheaders.set('5pCookie', Cookie);
          let options = { headers: this.authBaseAPIheaders };
          const data = await this.http.post(this.authBaseAPI + 'SetDeviceBinding', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await setDeviceBinding : ' + error);
          return error;
        }
    }

    async getDeviceBindingStatus(reqBody: any): Promise<any> {
        try {
          let options = { headers: this.authBaseAPIheaders };
          const data = await this.http.post(this.authBaseAPI + 'V1/GetDeviceBindingStatus', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await getDeviceBindingStatus : ' + error);
          return error;
        }
    }

    async userpinVerificationwithDevice(reqBody: any): Promise<any> {
        try {
          let options = { headers: this.authBaseAPIheaders };
          const data = await this.http.post(this.authBaseAPI + 'UserPinVerificationWithDevice', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await userpinverificationwithdevice : ' + error);
          return error;
        }
    }

    async setuserPin(Cookie:string, reqBody: any): Promise<any> {
        try {
            this.authBaseAPIheaders = this.authBaseAPIheaders.set('5pcookie', Cookie);
            let options = { headers: this.authBaseAPIheaders };
            const data = await this.http.post(this.authBaseAPI + 'V3/SetUserPin', reqBody, options).toPromise();
            return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await SetUserPin : ' + error);
          return error;
        }
    }

    async loginViaOTP(reqBody: any): Promise<any> {
        try {
            // this.authBaseAPIheaders = this.authBaseAPIheaders.set('5pcookie', Cookie);
            let options = { headers: this.authBaseAPIheaders };
            const data = await this.http.post(this.authBaseAPI + 'V1/LoginViaOTP', reqBody, options).toPromise();
            return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await loginViaOTP : ' + error);
          return error;
        }
    }

    async getOTPForLogin(reqBody: any): Promise<any> {
        try {
        //   this.authBaseAPIheaders = this.authBaseAPIheaders.set('Authorization', authorization);
          let options = { headers: this.authBaseAPIheaders };
          const data = await this.http.post(this.authBaseAPI + 'GetOTPForLogin', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await getOTPForLogin : ' + error);
          return error;
        }
    }

    async verifyLogin(reqBody: any): Promise<any> {
        try {
        //   this.pwaSpotbaseAPIheaders = this.pwaSpotbaseAPIheaders.set('Authorization', authorization);

        const authorization  = 'Basic NXBhaXNhOjVwYWlzYUB3ZWI=';
        this.boApiheaders = this.boApiheaders.set('Authorization', authorization);
        let options = { headers: this.boApiheaders };
          const data = await this.http.post(this.boApi + 'verify-login', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await verifyLogin : ' + error);
          return error;
        }
    }

    async getIncomeRange(authorization:string, reqBody: any): Promise<any> {
        try {
          this.boApiheaders = this.boApiheaders.set('Authorization', authorization);
          let options = { headers: this.boApiheaders };
          const data = await this.http.post(this.boApi + 'GetIncomeRangeAPI', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await getIncomeRange : ' + error);
          return error;
        }
    }

    async getClientInfo(authorization:string, reqBody: any): Promise<any> {
        try {
          this.boApiheaders = this.boApiheaders.set('Authorization', authorization);
          let options = { headers: this.boApiheaders };
          const data = await this.http.post(this.boApi + 'ClientInfo', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await getClientInfo : ' + error);
          return error;
        }
    }

    async loginRequest(reqBody: any): Promise<any> {
        try {
        //   this.pwaSpotbaseAPIheaders = this.pwaSpotbaseAPIheaders.set('Authorization', authorization);
        this.boApiheaders = this.boApiheaders.set('Authorization', 'Basic NXBhaXNhOjVwYWlzYUB3ZWI=');
          let options = { headers: this.boApiheaders };
          const data = await this.http.post(this.boApi + 'login-request', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await verifyLogin : ' + error);
          return error;
        }
    }

    async GenerateSmsOtpForRegistration(reqBody: any): Promise<any> {
        try {
          this.activationUrlHeaders = this.activationUrlHeaders.set('Ocp-Apim-Subscription-Key', this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).activationOcpkey))
          let options = { headers: this.activationUrlHeaders };
          const data = await this.http.post(this.activationUrl + 'GenerateSmsOtp_App', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await GenerateSmsOtpForRegistration : ' + error);
          return error;
        }
    }

    async VerifySmsOtp_ForRegistration(reqBody: any): Promise<any> {
        try {
          this.activationUrlHeaders = this.activationUrlHeaders.set('Ocp-Apim-Subscription-Key', this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).activationOcpkey))
          let options = { headers: this.activationUrlHeaders };
          const data = await this.http.post(this.activationUrl + 'VerifySmsOtp_App', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await VerifySmsOtp_ForRegistration : ' + error);
          return error;
        }
    }

    async getLoginAPI(reqBody: any): Promise<any> {
        try {
          let options = { headers: this.pwaSpotbaseAPIheaders };
          const data = await this.http.post(this.pwaSpotbaseAPI + 'V5/LoginRequestMobileNewbyEmail', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await getLoginAPI : ' + error);
          return error;
        }
    }

    async clientInfo(authorization:string, reqBody: any): Promise<any> {
        try {
           let aPIheaders = new HttpHeaders({
                'Authorization': authorization,
            });
            let options = { headers: aPIheaders };
            const data = await this.http.post(this.authBaseAPI + 'ClientInfo', reqBody, options).toPromise();
            return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await clientInfo : ' + error);
          return error;
        }
    }

    async clientLoginRequest(clientCodeFromVerifyOTP:string): Promise<any> {
        try {
          let options = { headers: this.authBaseAPIheaders };
          const data = await this.http.post(this.authBaseAPI + 'ClientLoginRequest?ClientCode='+ clientCodeFromVerifyOTP,'{}',options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await clientLoginRequest : ' + error);
          return error;
        }
    }

    async getDomainSso(jwtToken:string, reqBody: any): Promise<any> {
        try {
          this.boApiheaders = this.boApiheaders.set('Authorization', jwtToken);
          let options = { headers: this.boApiheaders };
          const data = await this.http.post(this.boApi + 'v1/get-domain-sso', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await getDomainSso : ' + error);
          return error;
        }
    }

    async insertClientSSO_Details(jwtToken:string, reqBody: any): Promise<any> {
        try {
          this.boApiheaders = this.boApiheaders.set('Authorization', jwtToken);
          let options = { headers: this.boApiheaders };
          const data = await this.http.post(this.boApi + 'insert-clientSSO-details', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await insertClientSSO_Details : ' + error);
          return error;
        }
    }

    async validateClientPercentage(jwtToken:string, reqBody: any): Promise<any> {
        try {
          this.boApiheaders = this.boApiheaders.set('Authorization', jwtToken);
          let options = { headers: this.boApiheaders };
          const data = await this.http.post(this.boApi + 'v1/validate-client-percentage', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await validateClientPercentage : ' + error);
          return error;
        }
    }

    async insertApiLog(basicAuth:string, reqBody: any): Promise<any> {
        try {
          this.boApiheaders = this.boApiheaders.set('Authorization', basicAuth);
          let options = { headers: this.boApiheaders };
          const data = await this.http.post(this.boApi + 'InsertCommonReqResLog', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await InsertLogForApi : ' + error);
          return error;
        }
    }

    async clientPersonalDetails(authorization:string, reqBody: any): Promise<any> {
        try {
          this.boApiheaders = this.boApiheaders.set('Authorization', authorization);
          let options = { headers: this.boApiheaders };
          const data = await this.http.post(this.boApi + 'ClientPersonalDetails', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await ClientPersonalDetails : ' + error);
          return error;
        }
    }

    async incomerange(authorization:string, reqBody: any): Promise<any> {
        try {
          this.boApiheaders = this.boApiheaders.set('Authorization', authorization);
          let options = { headers: this.boApiheaders };
          let timeoutDuration = this.storeDataServiceService.getApiTimeoutFirebaseConfigration(); // 3 seconds // take this value from firebase
          const delayDuration = this.storeDataServiceService.getDelayApiResponseTime(); // 3 seconds // take this value from firebase
          const data = await this.http.post(this.boApi + 'GetIncomeRangeAPI', reqBody, options)
            .pipe(
              delay(delayDuration), // Introduce a delay
              timeout(timeoutDuration), // Set a timeout
              catchError(async (error: any) => {
                return error;
              })
            )
            .toPromise();

      return data; // Return the resolved data
        }
        catch (error) {
          console.log('Error fetching data with Async/Await incomerange : ' + error);
          return error;
        }
    }

    async nomineeDetails(auth:string, Authorizationkey:string, reqBody: any): Promise<any> {
        try {
            let activationMoficationUrlHeaders = new HttpHeaders({
                'AuthenticationKey': auth,
                'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).OcpkeyNominee),
                'ReqSource': 'Web',
                'Authorization': Authorizationkey
            });

          let options = { headers: activationMoficationUrlHeaders };
          const data = await this.http.post(this.activationModificationUrl + 'CheckIfNomineeExists', reqBody, options)
  
          .toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await nomineeDetails : ' + error);
          return error;
        }
    }

    async activationTokenGenerate(clientcode: string, authorizationkey:string, reqBody: any): Promise<any> {
        try {
          const appName = 'login';
          let activationUrlHeaders = new HttpHeaders({
            'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).activationOcpkey),
            'ClientId': clientcode,
            'AppName': appName,
            'ReqSource': 'Web',
            'Authorization': authorizationkey,
        });

          let options = { headers: activationUrlHeaders };
          const data = await this.http.post(this.activationUrl + 'GenerateToken', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await ActivationGenerateToken : ' + error);
          return error;
        }
    }

    async nomineeOtp(Auth: string, Authorizationkey:string, reqBody: any): Promise<any> {
        try {
          let activationUrlHeaders = new HttpHeaders({
            'AuthenticationKey': Auth,
            'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).activationOcpkey),
            'ReqSource': 'Web',
            'Authorization': Authorizationkey,
        });

          let options = { headers: activationUrlHeaders };
          const data = await this.http.post(this.activationModificationUrl + 'SmsOtpForNominee', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await nomineeOtp : ' + error);
          return error;
        }
    }

    async consentDeclarationList(clientId: string): Promise<any> {
        try {

        let queryParams = new HttpParams();
        queryParams = queryParams.append('CompanyID', '2'); 

          let activationUrlHeaders = new HttpHeaders({
            'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).activationOcpkey),
            'ClientId': clientId,
            'AppName': 'Web'
        });

          let options = { headers: activationUrlHeaders };
          const data = await this.http.get(this.activationUrl + 'getConsentDeclarationList' + {params:queryParams}, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await consentDeclarationList : ' + error);
          return error;
        }
    }

    async updateIncomeRange(Authorization:string, reqBody: any): Promise<any> {
        try {
          this.boApiheaders = this.boApiheaders.set('Authorization', Authorization);
          let options = { headers: this.boApiheaders };
          const data = await this.http.post(this.boApi + 'UpdateIncomeRange', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await updateIncomeRange : ' + error);
          return error;
        }
    }

    async clientRegisterWeb(authorization, AuthenticationKey,reqBody: any): Promise<any> {
        try {

        let activationUrlHeaders = new HttpHeaders({
            'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).activationOcpkey),
            'ReqSource': 'Web',
            'Authorization': authorization,
            'AuthenticationKey': AuthenticationKey,
        });

          let options = { headers: activationUrlHeaders };
          const data = await this.http.post(this.activationUrl + 'QuickRegistration_New', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await clientRegisterWeb : ' + error);
          return error;
        }
    }

    async tokenGeneration(authorization:string, reqBody: any): Promise<any> {
        try {

            let boApiheaders = new HttpHeaders({
                'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).ocpApimSubscriptionKeyBoApi),
                'ReqSource': '5p',
                'Authorization': authorization,
            });
          
          let options = { headers: boApiheaders };
          const data = await this.http.post(this.boApi + 'GenerateToken', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await TokenGeneration : ' + error);
          return error;
        }
    }

    async validateVoucherCode(authorization:string, authenticationKey:string, reqBody: any): Promise<any> {
        try {

            let boApiheaders = new HttpHeaders({
                'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).ocpApimSubscriptionKeyBoApi),
                'ReqSource': '5p',
                'Authorization': authorization,
                'AuthenticationKey': authenticationKey,
            });
          
          let options = { headers: boApiheaders };
          const data = await this.http.post(this.boApi + 'ValidateVoucherCode', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await ValidateVoucherCode : ' + error);
          return error;
        }
    }

    async formAuthentication(reqBody: any): Promise<any> {
        try {
            let boApiheaders = new HttpHeaders({
                'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).ocpApimSubscriptionKeyBoApi)
            });
          
          let options = { headers: boApiheaders };
          const data = await this.http.post(this.boApi + 'FormAuthentication', reqBody, options)
          .toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await FormAuthentication : ' + error);
          return error;
        }
    }

    async checkLoginResponse(reqBody: any): Promise<any> {
        try {
            let tradeApiheaders = new HttpHeaders({
                'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).ocpApimSubscriptionKey)
            });
          
          let options = { headers: tradeApiheaders , withCredentials: true };
          const data = await this.http.post(this.tradeApiUrl + 'CheckLoginResponse', reqBody, options).toPromise();
          return data;
        }
        catch (error) {
          console.log('Error fetching data with Async/Await CheckLoginResponse : ' + error);
          return error;
        }
    }

    async validateFormAuthentication(reqBody: any): Promise<any> {
      try {
          let boApiheaders = new HttpHeaders({
              'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).ocpApimSubscriptionKeyBoApi)
          });
        
        let options = { headers: boApiheaders };
        const data = await this.http.post(this.boApi + 'ValidateFormAuthentication', reqBody, options).toPromise();
        return data;
      }
      catch (error) {
        console.log('Error fetching data with Async/Await ValidateFormAuthentication : ' + error);
        return error;
      }
  }
  async logout_user(req,jwtToken): Promise<any> {
    try {
      
      let pwaSpotbaseAPIheaders = new HttpHeaders({
        'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).ocpApimSubscriptionKey),
        'Authorization': jwtToken,
      });
      let options = { headers: pwaSpotbaseAPIheaders , withCredentials: true};
      const data =await this.http.get(this.pwaSpotbaseAPI +'logout', options).toPromise();
      return data;
    }
    catch (error) {
      console.log('Error fetching data with logout_user : ' + error);
      return error;
    }
  }
  async deleteCookieFromServer(reqBody): Promise<any> {
    try{
      let pwauatapi = this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).pwauatCookieApiUrl);
      let pwaApiheaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).cookieDomainAuthkey)
      });
      let options = { headers: pwaApiheaders };
      const data = await this.http.post(pwauatapi + 'DeleteCookie', reqBody, options).toPromise();
      return data;
    }
    catch (error) {
      console.log('Error fetching data with deleteCookieFromServer : ' + error);
      return error;
    }
    
  }
  async checkforTradestationRedirectionApi(reqBody: any,jwtToken): Promise<any> {
    try {
      let headers = new HttpHeaders({
        'Authorization': jwtToken,
      });
      let options = { headers: headers };
      const data = await this.http.post(this.boApi +'GetTSRedirectionClients', reqBody, options).toPromise();
      return data;
    }
    catch (error) {
      console.log('Error fetching data with Async/Await checkforTradestationRedirection : ' + error);
      return error;
    }
  }

  async verifyCaptchaToken(reqBody): Promise<any> {
    try{
      var tradeApiUrl= this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).tradeApiUrl);
      let header = new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': 'a4af51382266497bb5464d95fbb2017b'
      });
      let options = { headers: header };
      const data =  await this.http.post(tradeApiUrl + 'siteverify', reqBody, options).toPromise();
      return data;
    }
    catch (error) {
      console.log('Error fetching data with verifyCaptchaToken : ' + error);
      return error;
    }
    
  }
  async checkDormantClient(Authorization:string, reqBody: any): Promise<any> {
    try {
      let modificationUrl =this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).modificationUrl)
      let timeoutDuration = this.storeDataServiceService.getApiTimeoutFirebaseConfigration(); // 3 seconds // take this value from firebase
      let delayDuration = this.storeDataServiceService.getDelayApiResponseTime(); // 3 seconds // take this value from firebase
        let activationMoficationUrlHeaders = new HttpHeaders({
            'Ocp-Apim-Subscription-Key': this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).modificationOcpkey),
            'Authorization': Authorization
        });

      let options = { headers: activationMoficationUrlHeaders };
      const data = await this.http.post(modificationUrl + 'FetchDormantStatus_V2', reqBody, options)
      .pipe(
        delay(delayDuration), // Introduce a delay
        timeout(timeoutDuration), // Set a timeout
        catchError(async (error: any) => {
          return error;
        })
      )
      .toPromise();
      return data;
    }
    catch (error) {
      console.log('Error fetching data with Async/Await checkDormantClient : ' + error);
      return error;
    }
  }
  async GenerateVendorToken(Authorization:string,reqBody:any): Promise<any> {
    try{
      //var tradeApiUrl= this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).tradeApiUrl);
      var tradeApiUrl= "https://papi-preprod.5paisa.com/brokingcommonapi/api/VendorSSO/v1/"
      let header = new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': 'a4af51382266497bb5464d95fbb2017b',
        'Authorization': Authorization
      });
      let options = { headers: header };
      const data =  await this.http.post(tradeApiUrl + 'GenerateVendorToken', reqBody, options).toPromise();
      return data;
    }
    catch (error) {
      console.log('Error fetching data with GenerateVendorToken : ' + error);
      return error;
    }
    
  }
  async generateAuthorizationToken(): Promise<any> {
    try 
      {
        let activationheaders = new HttpHeaders({
          'Authorization': 'Basic NXBhaXNhX2t5Y19iYWNrZW5kX3Byb2Q6NXAta3ljQDE5MDFfcHJvZF92MQ=='
        });
        let options = { headers: activationheaders };
        const data = await this.http.get(this.kycApiUrl + 'generate/key?appSource=WEB', options).toPromise();
        return data;
      }
      catch (error) {
        return error;
      }
  }
  async generateAuthenticationkey(reqBody: any, token : string): Promise<any> {
    try 
      {
        let activationheaders = new HttpHeaders({
          'Authorization': token
        });
        let options = { headers: activationheaders };
        const data = await this.http.post(this.kycApiUrl + 'token',reqBody, options).toPromise();
        return data;
      }
      catch (error) {
        return error;
      }
  }
  async generateOtpWithMobileNumber(reqBody: any, authorizationtoken,authenticationkey): Promise<any> {
    try
    { 
      let activationheaders = new HttpHeaders({
        'Authorization': authorizationtoken,
        'AuthenticationKey': authenticationkey
      });
      let options = { headers: activationheaders };
      const data = await this.http.post(this.kycApiUrl + 'otp/generate',reqBody, options).toPromise();
      return data;

    }
    catch (error) {
      console.log('Error fetching data with generateOtpWithMobileNumber : ' + error);
      return error;
    }
  }
  async verifyOtp(reqBody: any, authorizationtoken,authenticationkey): Promise<any> {
    try
    { 
      let activationheaders = new HttpHeaders({
        'Authorization': authorizationtoken,
        'AuthenticationKey': authenticationkey
      });
      let options = { headers: activationheaders };
      const data = await this.http.post(this.kycApiUrl + 'otp/verify',reqBody, options).toPromise();
      return data;

    }
    catch (error) {
      console.log('Error fetching data with generateOtpWithMobileNumber : ' + error);
      return error;
    }
  }
  async registerUser(reqBody: any, authorizationtoken,authenticationkey): Promise<any> {
    try{
      let activationheaders = new HttpHeaders({
        'Authorization': authorizationtoken,
        'AuthenticationKey': authenticationkey
      });
      let options = { headers: activationheaders };
      const data = await this.http.post('https://kycapi.5paisa.com/user/register',reqBody, options).toPromise();
      return data
    }
    catch (error) {
      console.log('Error fetching data with registerUser : ' + error);
      return error;
    }
  }
  async redirectionToActivation(reqBody: any, authorizationtoken,authenticationkey): Promise<any> {
    try{
      let activationheaders = new HttpHeaders({
        'Authorization': authorizationtoken,
        'AuthenticationKey': authenticationkey
      });
      let options = { headers: activationheaders };
      const data = await this.http.post(this.kycApiUrl +'redirect',reqBody, options).toPromise();
      return data
    }
    catch (error) {
      console.log('Error fetching data with redirectionToKyc : ' + error);
      return error;
    }
  }
}