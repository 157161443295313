<div class="radio-group" *ngIf="returnUrl == null || returnUrl == ''">
    <label for="Tradestation" class="l-radio" [ngClass]="{'checked' : istradstation}">
      <input type="radio" (change)="handleChange($event)" [checked]="istradstation" id="Tradestation" name="colors" value="Tradestation" tabindex="1" />
      <span>Web Platform</span>
    </label>
    <label for="FnO" class="l-radio" [ngClass]="{'checked' : isFnO}">
      <input for="f-option" class="l-radio" type="radio" (change)="handleChange($event)" [checked]="isFnO" id="FnO" name="colors" value="FnO" tabindex="2" />
      <span>FnO</span>
    </label>
    <label for="Chart" class="l-radio" [ngClass]="{'checked' : isChart}">
      <input for="f-option" class="l-radio" type="radio" (change)="handleChange($event)" [checked]="isChart" id="Chart" name="colors" value="Chart" tabindex="3" />
      <span>Trade On Chart</span>
    </label>
  
  </div>