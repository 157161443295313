import { NgModule,CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';



import { LoginexistComponent } from './pre-login/loginexist/loginexist.component';
import { MatFormFieldModule } from '@angular/material/form-field';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatDialogModule} from '@angular/material/dialog';

import {MatCheckboxModule} from '@angular/material/checkbox';
import { DatePipe } from '@angular/common';
import { UpdateIncomeDetailsComponent } from './pre-login/dialog-box/update-income-details/update-income-details.component';
import {MatSelectModule} from '@angular/material/select';

import { OtpVerificationsComponent } from './pre-login/dialog-box/otp-verifications/otp-verifications.component';
import { OtpSuccessfullyVerifiedComponent } from './pre-login/dialog-box/otp-successfully-verified/otp-successfully-verified.component';
import { ThankYouComponent } from './pre-login/dialog-box/thank-you/thank-you.component';
import { AppSettings, APP_SETTINGS } from 'src/app/settings';
import { SettingsService } from 'src/app/settings.service';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { AngularFireRemoteConfigModule } from '@angular/fire/compat/remote-config';
import {
  FingerprintjsProAngularModule
} from '@fingerprintjs/fingerprintjs-pro-angular';
import { FreezeUnfreezeComponent } from './pre-login/dialog-box/freeze-unfreeze/freeze-unfreeze.component';
import { UrlSerializer } from '@angular/router';
import { CustomUrlSerializer } from './custom-url-serializer.service';



const appSettingsInitializer = (settingsService: SettingsService) => () =>
  settingsService.initialize();

const appSettingsFactory = (settingsService: SettingsService): AppSettings =>
  settingsService.settings;

@NgModule({
  declarations: [
    AppComponent,
    LoginexistComponent,
    UpdateIncomeDetailsComponent,
    OtpVerificationsComponent,
    OtpSuccessfullyVerifiedComponent,
    ThankYouComponent,
    FreezeUnfreezeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CarouselModule,
    MatDialogModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatSelectModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireRemoteConfigModule,
    FingerprintjsProAngularModule.forRoot({
      loadOptions: {
        apiKey: "HdLJvhpYq2bznMNuLBHF",
        region: "ap"
      }
    }),
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: appSettingsInitializer,
    deps: [SettingsService],
    multi: true,
  },
  {
    provide: APP_SETTINGS,
    useFactory: appSettingsFactory,
    deps: [SettingsService],
  },
  // { provide: UrlSerializer, useClass: CustomUrlSerializer },
  DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
