
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    constructor() {}

    static isEmpty(value: any): boolean {
        if (value) {
            if (value == null || value == undefined) {
                return true;
            } else if (typeof value === 'string') {
                if (value.trim().length == 0) {
                    return true;
                } else if (value == 'undefined') {
                    return true;
                } else {
                    return false;
                }
            } else if (value instanceof Array && value.length == 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    static CheckDomainValid(value: any) : boolean {    
        var pattern = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
        var result = pattern.test(value);
    
        if (result == true) {
          var name = value.split('/')[2];
          var finalDomain = name.split('.')[1];
          if (finalDomain === '5paisa') {
            return true;
          } else {
            return false;
          }
        } else {
          return result;
        }    
    }
}
