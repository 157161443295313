import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConstantService } from 'src/app/service/constant.service';
import { UtilService } from 'src/app/service/util.service';
import { StoreDataServiceService } from 'src/app/store-data-service.service';
import { OtpVerificationsComponent } from '../otp-verifications/otp-verifications.component';
import { LoginApiShareService } from 'src/app/service/login-api-share.service';
import { APP_SETTINGS, AppSettings } from 'src/app/settings';
import { EncryptionService } from 'src/app/service/encryption.service';
@Component({
  selector: 'app-urgent-nomination-registration',
  templateUrl: './urgent-nomination-registration.component.html',
  styleUrls: ['./urgent-nomination-registration.component.css']
})
export class UrgentNominationRegistrationComponent {

  clientCode : string;
  showLoader :boolean =false;

  ngOnInit(): void {
    this.showLoader=false;
    this.clientCode = this.constantService.getDecryptedLocalStorageData('clientCode');
    
  }
  constructor(
    @Inject(APP_SETTINGS) 
    private settings: AppSettings,
    private encryptionService: EncryptionService,
    public dialogRef: MatDialogRef<UrgentNominationRegistrationComponent>,
    public utilService : UtilService,
    private constantService: ConstantService,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    public storeDataServiceService :StoreDataServiceService,
    public loginApiShareService :LoginApiShareService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      // this.image = data.dataImage;
      dialogRef.disableClose = true;
   }

  updateNominee():void{
    window.location.href=this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).OptoutUrl);
  }
  async optOut_Nominee() : Promise<void>{
    this.showLoader=true;
    try{
      let token = await this.utilService.generateToken(this.clientCode);
      this.generateAndverifyOtp(token,'GenerateOTP');
    }
    catch(e){
      this.showLoader=false;
      console.log(e,"optOut_Nominee");
    }
  }
  
  async generateAndverifyOtp(token,otptype) : Promise<void>{
    try{
      
      const date = new Date();
      const clientCode = this.clientCode;
      const todayDt = this.datepipe.transform(date, this.constantService.checksumDateFormate);
      const parameters = 'Web' +clientCode + otptype + 'Client' + todayDt;
      let checksumkey = this.utilService.generateCheckSumActivation(parameters);
          let objrequest ={
            head: {
                CheckSumValue: checksumkey,
                IPAddress: this.storeDataServiceService.getIpAddress() == undefined ? this.constantService.LocalIP :this.storeDataServiceService.getIpAddress(),
                AppSource: "Web"
            },
            body: {
                ClientCode: this.clientCode,
                OTPType: otptype,
                OTP:"",
                UserType:"Client"
            }
           }
           const Auth ='123456789:' + token; // 123456789 value is static need to pass with token
           const Authorizationkey = this.getAuthKeyForNominee();
          
          await this.loginApiShareService.nomineeOtp(Auth,Authorizationkey,objrequest)
            .then(async(otpResponse: any) => {
              this.utilService.logApiData(this.clientCode,'nomineeOtp',JSON.stringify(objrequest),JSON.stringify(otpResponse));
              if(otpResponse != null){
                if(otpResponse.head.Status =='0' && otpResponse.body.Status =='0'){
                  this.showLoader=false; // hide loader
                  this.dialogRef.close('success');
                  this.enterOptForNominee();
                }
                else if(otpResponse.head.Status =='1' && otpResponse.body.Status =='1'){
                  this.showLoader=false; // hide loader
                }
                else if(otpResponse.head.Status =='0' && otpResponse.body.Status =='1'){
                  this.showLoader=false; // hide loader
                }
              }
              
            })
            .catch(async (error) => {
                if (error.response.status == 403 || error.response.status == 401) {
                  await this.optOut_Nominee();
                  this.showLoader=false; // hide loader
                }
          });
    }
    catch(e){
      console.log(e,"generateAndverifyOtp");
    }
  }
  getAuthKeyForNominee(): string {
    let authkey=null;
    try{
       authkey ='Basic ' + this.utilService.convertTextToBase64(this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).tokenUserName) + ':' + this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).tokenPassword));
       return authkey;
    }
    catch(e){
      console.log(e,"NomineegetAuthKey");
      return authkey =null;
    }
    
  }
  enterOptForNominee(): void{
    try{
      const dialogRef = this.dialog.open(OtpVerificationsComponent, {});
      dialogRef.afterClosed().subscribe(result => {
        if(result =='success'){
          
        }
      });
    }
    catch(e){
      console.log(e,"enterOptForNominee");
    }
  }
 

}
