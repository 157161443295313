<app-loader *ngIf="showLoader"></app-loader>
<div *ngIf="!showLoader">
    <div id="app-cover" class="app-cover hide">
        <div class="row">
            <div class="toggle-button-cover">
                <div class="button-cover">
                    <div class="themeLabel">
                        <span>Theme</span>
                    </div>
                    <div class="button r" id="button-1">
                        <input
                            type="checkbox"
                            [checked]="isChecked"
                            class="checkbox"
                            (click)="isChangedtheme()"/>
                        <div class="knobs"></div>
                        <div class="layer"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <button class="sticky-btn" (click)="toggleDarkMode()">Dark Mode</button> -->
    <div class="login-5paisa">
        <div class="row">
            <div class="text-center login-mobile-view">
                <div class="logo_wrap">
                    <h2>Welcome to</h2>
                    <a href="https://www.5paisa.com" target="_blank" title="5Paisa">
                        <img
                            *ngIf="isChecked ==false"
                            src="https://images.5paisa.com/login/5paisalogonew.svg"
                            class="img-responsive logo-img"
                            alt="logo">

                            <!-- For Dark Theme  -->
                            <img
                                *ngIf="isChecked ==true"
                                src="https://images.5paisa.com/login/5PaisaDarkLogo.svg"
                                class="img-responsive logo-img"
                                alt="logo"/>
                        </a>
                    </div>
                </div>
                <div class="col-6 box-left">
                    <div class="box_wrap text-center">
                        <div class="logo_wrap">
                          <h2>Welcome to</h2>
                          <a href="https://www.5paisa.com" target="_blank" title="5Paisa">
                              <img
                                  *ngIf="isChecked ==false"
                                  src="https://images.5paisa.com/login/5paisalogonew.svg"
                                  class="img-responsive logo-img"
                                  alt="logo">
      
                                  <!-- For Dark Theme  -->
                                  <img
                                      *ngIf="isChecked ==true"
                                      src="https://images.5paisa.com/login/5PaisaDarkLogo.svg"
                                      class="img-responsive logo-img"
                                      alt="logo"/>
                              </a>

                                <img
                                    *ngIf="isChecked ==false"
                                    src="https://images.5paisa.com/login/login-banner.gif"
                                    class="animatedBanner"
                                    alt="light-banner"/>
                                <!-- For Dark Theme banner  -->
                                <img
                                    *ngIf="isChecked ==true"
                                    src="https://images.5paisa.com/SPOT/login/dark-login-banner.gif"
                                    class="animatedBanner"
                                    alt="dark-banner"/>
                                <owl-carousel-o [options]="customOptions">
                                    <ng-template carouselSlide>
                                        <p>
                                            Powerful platform for lightning fast trading and easy trade execution.
                                        </p>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <p>
                                            Intuitive design and single page view for complete details of a company.
                                        </p>
                                    </ng-template>
                                    <ng-template carouselSlide>
                                        <p>
                                            Advanced charts and custom watchlist for informed investing decisions.
                                        </p>
                                    </ng-template>
                                </owl-carousel-o>
                            </div>
                        </div>
                    </div>

                    <div class="col-6 box-right">
                        <div class="align-items-center justify-content-center d-flex h-100">
                            <router-outlet></router-outlet>
                        </div>

                    </div>
                </div>
            </div>
            <footer class="navigation--footer footer">
                <div class="container">
                    <p class="navigation--footer-text">
                        SEBI Registration Number : INZ000010231, SEBI RA Regn.: INH000004680,
                        IN-DP-192-2016 | AMFI REGN No.: ARN-104096 | CIN : L67190MH2007PLC289249
                    </p>
                    <p class="navigation--footer-link">
                        <a href="https://www.5paisa.com/about-us" target="_blank">About Us</a>
                        |
                        <a href="https://www.5paisa.com/privacy-policy" target="_blank">Privacy Policy</a >
                        |
                        <a href="https://www.5paisa.com/disclaimer" target="_blank">Disclaimer</a>
                        |
                        <a href="https://www.nseindia.com/" target="_blank">NSE</a>
                        |
                        <a href="https://www.bseindia.com/" target="_blank">BSE</a>
                        |
                        <a href="https://www.mcxindia.com/" target="_blank">MCX</a>
                        |
                        <a href="https://www.sebi.gov.in/" target="_blank">SEBI</a>
                        |
                        <a href="https://scores.gov.in/scores/Welcome.html" target="_blank">SEBI Scores</a >
                        |
                        <a href="https://rbi.org.in/" target="_blank">RBI</a>
                    </p>
                    <p class="navigation--footer-nse">
                        NSE Member ID : 14300 | BSE Clg No : 6363 | MCX Member ID : 55945
                    </p>
                    <p class="navigation--footer-text">
                        Copyright 2023 5paisa Capital Ltd. All Rights Reserved.
                    </p>
                </div>

                <div id="back-to-top" class="hide">
                    <img src="https://images.5paisa.com/login/up-arrow.png" alt="Go to 5paisa top"/>
                </div>
            </footer>
        </div>