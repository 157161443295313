<app-loader *ngIf="showLoader"></app-loader>
<div class="inner-wrapper">
    <app-option-selection></app-option-selection>
    <form class="form-field" [formGroup]="loginExist" novalidate>
        <div class="loginexist-name">
            <h3 *ngIf="userNameFromStorage != ''">Hello, <span class="text-pink"><strong>{{userNameFromStorage}}</strong></span></h3>
            <div class="client-code-details">
                <span class="client-code">{{clientCodeFromStorage}}</span>
                <span class="link" (click)="changeLogin()" >Switch Account</span>
            </div>
        </div>
        <div class="label--wrapper">
            <label>Unlock Using PIN</label>
            <span class="link" (click)="forgotMPIN()">FORGOT PIN</span>
        </div>

        <div class="otp-verifications-input">
            <div class="otp-box">
                <div class="otp-box">

                    <input
                    [ngClass]="{'errorbox': incorrectPIN == true}"
                    type="password"
                    maxlength="1"
                    #pinOne
                    [(ngModel)]="otpArr[0]"
                    formControlName="pinDigitOne"
                    autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\.*)\./g, '$1');"
                    matInput
                    (keydown)="onKeyUpHandleFocus($event,0)"
                    />
                    <input
                    [ngClass]="{'errorbox': incorrectPIN == true}"
                    type="password"
                    maxlength="1"
                    #pinTwo
                    [(ngModel)]="otpArr[1]"
                    formControlName="pinDigitTwo"
                    autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\.*)\./g, '$1');"
                    matInput
                    (keydown)="onKeyUpHandleFocus($event,1)"
                    />
                    <input
                    [ngClass]="{'errorbox': incorrectPIN == true}"
                    type="password"
                    maxlength="1"
                    #pinThree
                    [(ngModel)]="otpArr[2]"
                    formControlName="pinDigitThree"
                    autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\.*)\./g, '$1');"
                    matInput
                    (keydown)="onKeyUpHandleFocus($event,2)"
                    />
                    <input
                    [ngClass]="{'errorbox': incorrectPIN == true}"
                    type="password"
                    maxlength="1"
                    #pinFour
                    [(ngModel)]="otpArr[3]"
                    formControlName="pinDigitFour"
                    autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\.*)\./g, '$1');"
                    matInput
                    (keydown)="onKeyUpHandleFocus($event,3)"
                    />
                    <input
                    [ngClass]="{'errorbox': incorrectPIN == true}"
                    type="password"
                    maxlength="1"
                    #pinFive
                    [(ngModel)]="otpArr[4]"
                    formControlName="pinDigitFive"
                    autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\.*)\./g, '$1');"
                    matInput
                    (keydown)="onKeyUpHandleFocus($event,4)"
                    />
                    <input
                    [ngClass]="{'errorbox': incorrectPIN == true}"
                    type="password"
                    maxlength="1"
                    #pinSix
                    [(ngModel)]="otpArr[5]"
                    formControlName="pinDigitSix"
                    autocomplete="off"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\.*)\./g, '$1');"
                    matInput
                    (keydown)="onKeyUpHandleFocus($event,5)"
                    (keydown.enter)="verifyPin()"
                    />
                </div>
            </div>
            <div *ngIf="incorrectPIN">
                <span class="error-text">{{ pinError }}</span>
            </div>
        </div>
        <div id="example-container"  data-theme ='auto'></div>
        <button class="btn-dark btn-block" #btnVerified id="btnVerifyOTPMPIN" [disabled]="loginExist.invalid" (click)="verifyPin()">Proceed</button>
</form>
</div>