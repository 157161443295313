<div class="two-fa thankYou">

    <div class="inner-wrapper">
        <!-- <div class="progress-steps">
            <div class="step-count completed">1
            </div>
            <div class="step-count completed">2
            </div>
        </div> -->
        <div class="image-wrapper">
            <lottie-player src="https://lottie.host/e4e80e2b-e9e1-46c2-aa21-6f15e4ba9435/FvdOVp4ifx.json" background="transparent" speed="1" style="width: 209px; height: 209px; margin: 0 auto;" direction="1" mode="normal" loop autoplay></lottie-player>
        </div>
        <h2>Thank You</h2>
        <h6>Thank you for declaring your details </h6>

        <div class="declaration">
        </div>
        <div class="bottom-button-wrapper">
            <button class="btn-dark" (click)="closepopup()">Close</button>
        </div>

    </div>
</div>